import i18n from 'src/plugins/i18n'

function getErrorMessage (errorKey, args) {
  return i18n.t(errorKey, args)
}

function required (errorKey) {
  return value => !!value || value === 0 || getErrorMessage(errorKey)
}

function between (errorKey, { min, max }) {
  return value => !(value < min || value > max) || getErrorMessage(errorKey, { min, max })
}

function minLength (errorKey, { length }) {
  return value => value?.length >= length || getErrorMessage(errorKey, { length })
}

function maxLength (errorKey, { length }) {
  return value => value?.length <= length || getErrorMessage(errorKey, { length })
}

function exactLength (errorKey, { length }) {
  return value => value?.length === length || getErrorMessage(errorKey, { length })
}

function noSpaces (errorKey) {
  return value => !(value?.includes(' ') ?? false) || getErrorMessage(errorKey)
}

function equal (errorKey, { compared }) {
  return value => value === compared || getErrorMessage(errorKey)
}

function except (errorKey, { list }) {
  return value => !list.includes(value) || getErrorMessage(errorKey)
}

function alpha (errorKey) {
  return value => /^[a-zA-Z]+$/.test(value) || getErrorMessage(errorKey)
}

function alphanumeric (errorKey) {
  return value => /^[a-zA-Z0-9]+$/.test(value) || getErrorMessage(errorKey)
}

function greater (errorKey, { number }) {
  return value => value > number || getErrorMessage(errorKey, { number })
}

function lower (errorKey, { number }) {
  return value => value < number || getErrorMessage(errorKey, { number })
}

function regexp (errorKey, { test }) {
  return value => !!value.match(test) || getErrorMessage(errorKey)
}

function email (errorKey) {
  return value => !!value.match(/\S+@\S+\.\S+/) || getErrorMessage(errorKey)
}

export default {
  required,
  between,
  minLength,
  maxLength,
  exactLength,
  noSpaces,
  equal,
  except,
  alpha,
  alphanumeric,
  greater,
  lower,
  regexp,
  email,
}
