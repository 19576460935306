<template>
  <ag-card-container centered :title="$t('title.login')">
    <template #input>
      <ag-language-field
        :value="language"
        hide-details
        single-line
        style="width: 175px;"
        @input="setDefaultLanguage"
      />
    </template>
    <login-form
      :loading="loading"
      class="ma-8"
      @input="sendLoginRequest"
    />
  </ag-card-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AgCardContainer from 'src/components/common/AgCardContainer'
import AgLanguageField from 'src/components/common/AgLanguageField'
import LoginForm from '../components/LoginForm'

export default {
  name: 'LoginContainer',
  components: {
    AgCardContainer,
    LoginForm,
    AgLanguageField,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters('me', {
      language: 'language',
    }),
  },
  methods: {
    ...mapActions('me', {
      login: 'login',
      setDefaultLanguage: 'setDefaultLanguage',
    }),
    async sendLoginRequest (credentials) {
      this.loading = true
      if (await this.login(credentials)) {
        this.$router.replace({ name: 'Home' })
      }
      this.loading = false
    },
  },
}
</script>
