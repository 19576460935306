<template>
  <ag-form
    :submit-label="$t('general.connect')"
    :submit-loading="loading"
    @submit="submit"
  >
    <v-text-field
      v-model="credential.sessionCode"
      :label="$t('login.fields.sessionCode')"
      :rules="$options.rules.sessionCode"
      prepend-icon="mdi-presentation"
      type="text"
    />
    <v-text-field
      v-model="credential.playerCode"
      :label="$t('login.fields.playerCode')"
      :rules="$options.rules.playerCode"
      prepend-icon="mdi-key"
      type="text"
    />
    <v-text-field
      v-model="credential.playerName"
      :label="$t('login.fields.playerName')"
      :rules="$options.rules.playerName"
      prepend-icon="mdi-account"
      type="text"
    />
    <v-text-field
      v-model="credential.playerEmail"
      :label="$t('login.fields.playerEmail')"
      :rules="credential.playerEmail ? $options.rules.playerEmail : []"
      prepend-icon="mdi-email"
      type="mail"
    />
  </ag-form>
</template>

<script>
import FormRules from 'src/utils/form-rules'
import AgForm from 'src/components/common/AgForm'

export default {
  name: 'LoginForm',
  components: {
    AgForm,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    credential: {
      sessionCode: '',
      playerCode: '',
      playerName: '',
      playerEmail: '',
    },
  }),
  methods: {
    submit () {
      this.$emit('input', this.credential)
    },
  },
  rules: {
    sessionCode: [
      FormRules.required('error.form.required'),
      FormRules.alphanumeric('error.form.alphanumeric'),
      FormRules.exactLength('error.form.exactLength', { length: 6 }),
    ],
    playerCode: [
      FormRules.required('error.form.required'),
      FormRules.exactLength('error.form.exactLength', { length: 3 }),
      FormRules.regexp('login.formrules.playerCodeFormat', { test: /^[a-zA-Z]\d{2}$/ }),
    ],
    playerName: [
      FormRules.required('error.form.required'),
      FormRules.minLength('error.form.minLength', { length: 2 }),
    ],
    playerEmail: [
      FormRules.email('error.form.email'),
    ],
  },
}
</script>
