<template>
  <v-form ref="form" lazy-validation>
    <slot />
    <div v-if="!hideControls" class="text-center">
      <v-btn
        v-if="$listeners.cancel"
        :loading="cancelLoading"
        :disabled="isLoading"
        color="primary"
        outlined
        class="mr-4"
        @click="$emit('cancel')"
        v-text="cancelLabel"
      />
      <v-btn
        :loading="submitLoading"
        :disabled="isLoading"
        color="accent"
        class="mr-4"
        @click="submit"
        v-text="submitLabel"
      />
    </div>
  </v-form>
</template>

<script>
export default {
  name: 'StandardForm',
  props: {
    hideControls: {
      type: Boolean,
      default: false,
    },
    submitLabel: {
      type: String,
      default: 'Submit',
    },
    submitLoading: {
      type: Boolean,
      default: false,
    },
    cancelLabel: {
      type: String,
      default: 'Cancel',
    },
    cancelLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLoading () {
      return this.submitLoading || this.cancelLoading
    },
  },
  methods: {
    submit () {
      if (this.$refs.form.validate()) {
        this.$emit('submit')
      }
    },
  },
}
</script>
