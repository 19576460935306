<template>
  <v-select
    :items="languages"
    :rules="$options.rules.language"
    prepend-icon="mdi-web"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import FormRules from 'src/utils/form-rules'

export default {
  name: 'AgLanguageField',
  computed: {
    languages () {
      return [
        { value: 'fr', text: this.$t('language.french') },
        { value: 'en', text: this.$t('language.english') },
      ]
    },
  },
  rules: {
    language: [
      FormRules.required('error.form.required'),
    ],
  },
}
</script>
